import React, { useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import useToken from "./../hooks/useToken";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import BoutonRetour from "../components/ui/BoutonRetour";
import { safeJsonFetch,safeFetch, FAILED } from "../utils";
import LoadFailed from "../components/ui/LoadFailed";
import { Temporal } from '@js-temporal/polyfill';
import "./ticket.css";

const TEMPORAL_NOW = Temporal.Now.plainDateTimeISO();
const RELATIVE_TIME_FORMAT = new Intl.RelativeTimeFormat('fr', { numeric: 'auto' });
// Unité de temps à garder trié par ordre décroissant
const DATE_UNITS = [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
    "seconds",
];

export async function ticketLoader({ params },token) {
    let url;
    let collectiviteData;
    if (params?.collectiviteId) {
        collectiviteData = await safeJsonFetch(
            `${API_URL}/admin/collectivite/${params.collectiviteId}`,
           token
        )

        url = `${API_URL}/admin/collectivite/${params.collectiviteId}/ticket/${params.ticketId}`;
    } else {
        url = `${API_URL}/ticket/${params.ticketId}`;
    }

    const ticket = await safeJsonFetch(
        url,
        token
    );
    return {
        ticket,
        collectiviteData
    };
}

function getRelativeTimeString(date) {
    // On calcule "l'age" du commentaire
    const duration =  TEMPORAL_NOW.until(
        Temporal.PlainDateTime.from(date),
        { smallestUnit: 'minutes', largestUnit: 'years' }
    );

    // On recherche l'unité la plus adaptée pour l'affichage ( par exemple, si le commentaire
    // a plus d'un an, alors on veut afficher la durée en année, sinon, s'il a plus d'un mois,
    // on veut afficher en mois, etc...
    // Si le commentaire vient d'etre ajouté, on peut ne pas avoir trouvé d'unité
    // (car l'age est de 0 secondes par exemple), et dans ce cas la, on utilise la plus petite
    // unité (la dernière du tableau)
    const unit = DATE_UNITS.find(u => duration[u] < 0) || DATE_UNITS.at(-1);
    return RELATIVE_TIME_FORMAT.format(duration[unit], unit);
}

function Ticket(props) {

    let {
        ticket,
        collectiviteData,
    } = useLoaderData();
    const params = useParams();

    const [state, setState] = useState({
        adding: false,
        added: null,
        ticket: ticket,
    });

    const { token } = useToken();

    const onFormSubmit = async e => {
        setState({
            ...state,
            adding: true,
            added: null,
        });

        // ajout commentaire automatique dans GLPI
        e.preventDefault();
        const formData = new FormData(e.target);

        let action = e.nativeEvent.submitter.name;
        let url = `${API_URL}/ticket/${ticket.id}/comment/create`;
        if (["valider", "refuser"].includes(action)) {
            url = `${API_URL}/ticket/${ticket.id}/${action}`;
        }

        try {
            const response = await safeFetch(
                url,
                token,
                {
                    method: "POST",
                    body: formData,
                }
            );
            if (response === FAILED) {
                throw new Error();
            }

            let data = await response.json();
            if (["valider", "refuser"].includes(action)) {
                data = data.comment;
            }

            if (data === false) {
                throw new Error();
            }

            let updatedTicket = state.ticket;
            updatedTicket.commentaires = updatedTicket.commentaires.concat(data);

            if (["valider", "refuser"].includes(action)) {
                updatedTicket.status = (action === "valider" ? 6 : 2); // clos, en cours
                updatedTicket.statut = (action === "valider" ? "Clos" : "En cours (Attribué)");
                let lastSolution = updatedTicket.commentaires.findLast((comment) => comment.type === "solution" && comment.status === 2);
                if(lastSolution) {
                    lastSolution.status = (action === "valider" ? 3 : 4); // validé, refusé
                }
            } else {
                updatedTicket.status =  2;
                updatedTicket.statut = "En cours (Attribué)";
            }

            setState({
                ...state,
                added: true,
                adding: false,
                ticket: updatedTicket,
            });
        } catch(ex) {
            setState({
                ...state,
                added: false,
                adding: false,
            });
        }
    };

    const detailsTask = !!params?.collectiviteId;

    return (
        <DetailTicket
            data={state.ticket}
            onFormSubmit={!params?.collectiviteId ? onFormSubmit : undefined}
            added={state.added}
            adding={state.adding}
            detailsTask={detailsTask}
            nomCollectivite={collectiviteData ? collectiviteData.nom : null}
            vueAdmin={props.vueAdmin}
        ></DetailTicket>)
}

export function DetailTicket(props) {
    const lis = [];
    const ticket = props.data;
    const commentaires = ticket.commentaires;
    // ticket "résolu", on doit afficher le formulaire de validation
    const needValidation = ticket.status === 5;

    if (ticket !== FAILED) {
        commentaires.forEach(commentaire => {
            lis.push(
                <li
                    key={commentaire.id}
                    className={
                        commentaire.is_written_by_self
                        ? "commentaire-from-user-collectivite"
                        : commentaire.is_private && commentaire.type === 'comment'
                        ? "commentaire-prive"
                        : commentaire.type === "task"
                        ? "commentaire-tache"
                        : commentaire.type === "solution"
                        ? "commentaire-solution"
                        : null
                    }
                    data-status={commentaire.status}
                >
                    {
                        commentaire.is_private && commentaire.is_private === true
                        ? <FontAwesomeIcon
                            icon={solid("lock")}
                            size="lg"
                            className="icone-private"
                            title={
                                commentaire.type === 'task'
                                ? "Cette tâche n'est pas consultable par la collectivité "
                                : "Ce commentaire n'est pas consultable par la collectivité"
                            }
                        />
                        : null
                    }
                    <div>
                        {
                            commentaire.type === 'task'
                            ? <>
                                <FontAwesomeIcon
                                    icon={solid("wrench")}
                                    size="lg"
                                    className="icone-task"
                                    title={
                                        commentaire.status === 1
                                        ? "Tâche prévue"
                                        : commentaire.status === 2
                                        ? "Tâche réalisée"
                                        : "Tâche"
                                    }
                                />
                                {
                                    commentaire.status === 1 || commentaire.status === 2
                                    ? <span className="task-status">
                                        {
                                            // L'API retourne le status comme integer, on doit donc les convertir nous-même en string affichable
                                            // voir https://github.com/glpi-project/glpi/blob/bb82202a3560de9a534c9be76f28686dcb18be3e/src/Planning.php#L77-L79
                                            // On ignore le statut 0 correspondant à 'Information' puisque cela n'ajoute aucune information supplémentaire pour l'utilisateur
                                            commentaire.status === 1
                                            ? "A\u00A0faire"
                                            : commentaire.status === 2
                                            ? "Fait"
                                            : null
                                        }
                                    </span>
                                    : null
                                }
                            </>
                            : <FontAwesomeIcon
                                icon={regular("comment")}
                                size="lg"
                                className="icone-commentaire"
                            />
                        }
                        <span className="user-commentaire">{commentaire.user}</span>
                    </div>
                    {
                        props.detailsTask
                            ? <TaskDetail commentaire={commentaire}></TaskDetail>
                            : null
                    }
                    <div
                        dangerouslySetInnerHTML={{__html:commentaire.description}}
                        className="contenu-commentaire">
                    </div>
                    <div className="date-commentaire" title={commentaire.date}>
                        {
                            commentaire.type === "solution"
                            ? "Solution proposée " + getRelativeTimeString(commentaire.date) + ","
                            : getRelativeTimeString(commentaire.date)
                        }
                    </div>
                    {
                        commentaire.type === "solution"
                        // pour trouver l'équivalence entre status numérique retourné par l'api et string affichable :
                        // voir https://github.com/glpi-project/glpi/blob/10.0/bugfixes/src/CommonITILValidation.php#L57
                        // et/ou la recherche de ticket dans l'interface web de glpi
                        ? <div className="solution-status" title={commentaire.status_date}>{
                                commentaire.status === 2 ? "en attente de validation" :
                                commentaire.status === 3 ? "validée" :
                                commentaire.status === 4 ? "refusée" :
                                null
                            } {
                                commentaire.status_date
                                ? " " + getRelativeTimeString(commentaire.status_date)
                                : null
                            }
                        </div>
                        : null
                    }
                </li>
            )
        });
    }

    return (
        <section className={"section-contenu-page page-ticket" + (props.vueAdmin ? " page-ticket-admin" : "")}>
            {
                ticket === FAILED
                    ? <LoadFailed/>
                    : <>
                        <div className="page-top-section-header">
                            {
                                props.vueAdmin
                                    ? <span className="nom-collectivite">{props.nomCollectivite}</span>
                                    : null
                            }
                            <h1 className="titre-page">
                                <div>
                                    <FontAwesomeIcon icon={solid("ticket")} size="sm" className="icone-titre-page"/>
                                    Demande d'assistance <span className="id-ticket">#{ticket.id}</span>
                                </div>
                            </h1>
                        </div>
                        <section className="section-details-ticket">
                            <section className="section-identification-ticket">
                                <h2>
                                    {
                                        ticket.categorie !== null
                                            ? <span>{ticket.categorie}&nbsp;: </span>
                                            : null
                                    }
                                    <span dangerouslySetInnerHTML={{__html:ticket.titre}}></span>
                                </h2>
                                <div className="infos-ticket">
                                    <section>
                                        <div className="statut-ticket" data-statut-ticket={ticket.statut}>
                                            {ticket.statut}
                                        </div>
                                        <div>{ticket.type}</div>
                                        <div>{
                                            // Les entités commencent toutes par 'Entité racine > ' suivi du nom,
                                            // on ne veut afficher que la chaine de caractères qui suit.
                                            ticket.entite.replace(/^Entité racine > /, "")
                                        }</div>
                                    </section>
                                    <section className="dates-ticket">
                                        <div>Ouvert le {new Date(ticket.date_ouverture).toLocaleDateString()}</div>
                                        <div><abbr title="Derniere mise à jour">MAJ</abbr> le {new Date(ticket.date_modification).toLocaleDateString()}</div>
                                    </section>
                                </div>
                                <div className="label-description">
                                    Description:
                                </div>
                                <div
                                    className="description-ticket"
                                    dangerouslySetInnerHTML={{__html:ticket.description}}>
                                </div>
                            </section>
                            <hr />
                            <ul className="liste-commentaires">
                                {lis}
                            </ul>
                            {
                                props.onFormSubmit ? (
                                    <form
                                        className="form-ajout-commentaire"
                                        onSubmit={props.onFormSubmit}
                                    >
                                        <label htmlFor="content">
                                            {
                                                needValidation
                                                ? "Valider la solution"
                                                : "Ajouter un commentaire"
                                            }
                                        </label>
                                        {
                                            props.added === false
                                            ? <div className="error-msg">Le commentaire n'a pas pu être ajouté.<br/>Veuillez réessayer ultérieurement.</div>
                                            : null
                                        }
                                        <textarea
                                            // on veut vider le textarea lorsqu'un commentaire est ajouté,
                                            // on change donc la key de l'élément en fonction du nombre de commentaires
                                            // cf https://react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
                                            key={"commentaire-" + commentaires.length}
                                            name="content"
                                            id="content"
                                            required
                                            rows="10"
                                            className="input-commentaire-ticket"
                                            disabled={props.adding ? true : null}
                                        />
                                        <section className="boutons-action">
                                            {
                                                needValidation
                                                ? <>
                                                    <button
                                                        type="submit"
                                                        className="bouton-validation"
                                                        disabled={props.adding ? true : null}
                                                        name="valider"
                                                    >
                                                        {props.adding ? "Enregistrement" : <><FontAwesomeIcon icon={solid("check")} /> Approuver la solution</>}
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="bouton-refus"
                                                        disabled={props.adding ? true : null}
                                                        name="refuser"
                                                    >
                                                        {props.adding ? "Enregistrement" : <><FontAwesomeIcon icon={solid("x")} /> Refuser la solution</>}
                                                    </button>
                                                </> :
                                                    <button
                                                        type="submit"
                                                        className="submit-btn submit-comment"
                                                        disabled={props.adding ? true : null}
                                                    >
                                                        {props.adding ? "Enregistrement" : "Commenter"}
                                                    </button>
                                            }
                                            <button
                                                type="reset"
                                                className="secondary-btn"
                                                disabled={props.adding ? true : null}
                                            >
                                                Annuler
                                            </button>
                                        </section>
                                    </form>
                                ) : null
                            }
                        </section>
                    </>
            }
        </section>
    );
}

function TaskDetail(props) {
    const { commentaire } = props;

    if (
        !commentaire.task_data ||
        (
            !commentaire.task_data.user_assigned_task &&
            !commentaire.task_data.group_assigned_task &&
            commentaire.task_data.duration === 0 &&
            commentaire.task_data.date_begin_task == null
        )
    ) {
        return null;
    }

    const hasUser = commentaire.task_data.user_assigned_task;
    const hasGroup = commentaire.task_data.group_assigned_task;
    const hasGroupAndUser = hasUser && hasGroup;
    let elUser;
    if (hasUser || hasGroup) {
        let text;
        if (hasGroupAndUser) {
            text = commentaire.task_data.user_assigned_task + ' - ' + commentaire.task_data.group_assigned_task;
        } else if (hasGroup) {
            text = commentaire.task_data.group_assigned_task
        } else {
            text = commentaire.task_data.user_assigned_task
        }
        elUser = (
            <span>
                <FontAwesomeIcon
                    icon={hasGroup ? solid("users") : solid("user")}
                    size="lg"
                    className="icone-details-task"
                />
                Assignée à {text}
            </span>
        );
    }

    let taskDuration = null;
    if(commentaire.task_data.duration !== 0) {
        const duration = Temporal.Duration.from({
            seconds: commentaire.task_data.duration,
        }).round({
            smallestUnit: 'minute',
            largestUnit:'hour'
        });

        const taskHourDuration = duration.hours;
        const taskMinuteDuration = duration.minutes;

        const heures = taskHourDuration > 0
            ? taskHourDuration + ' heure' + (taskHourDuration > 1 ? 's' : '')
            : null;
        const minutes = taskMinuteDuration > 0
            ? taskMinuteDuration + ' minute' + (taskMinuteDuration > 1 ? 's' : '')
            : null;

        if (taskHourDuration > 0 && taskMinuteDuration > 0) {
            taskDuration = heures + ' ' + minutes;
        } else if (taskHourDuration > 0) {
            taskDuration = heures;
        } else if (taskMinuteDuration > 0) {
            taskDuration = minutes;
        }
    }

    return (
        <div className="details-task">
            {elUser}
            {taskDuration ? (
                <span>
                    <FontAwesomeIcon
                        icon={solid("stopwatch")}
                        size="lg"
                        className="icone-details-task"
                        title="Durée"
                    />
                    {taskDuration}
                </span>
            ) : null}
            {commentaire.task_data.date_begin_task !== null ? (
                <span>
                    <FontAwesomeIcon
                        icon={solid("calendar")}
                        size="lg"
                        className="icone-details-task"
                    />
                    Planifiée du {new Date(commentaire.task_data.date_begin_task).toLocaleString() + ' au ' + new Date(commentaire.task_data.date_end_task).toLocaleString()}
                </span>
            ) : null}
        </div>
    );
}

export default Ticket;
